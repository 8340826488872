import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Usuario } from '@shared/models/usuario';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(protected http: HttpClient) { }

  public getUsuarios(): Observable<Usuario[]> {
    return this.http
      .get(`${environment.localBack}usuario`)
      .pipe(map((response) => response as Usuario[]));
  }
  public getUsuariosPaginado(page:any,size?:any): Observable<any> {
    return this.http
      .get(`${environment.localBack}usuario/paginado`,
      { 
        params: {
          page:page,
          size:size
        }
        })
      .pipe(map((response) => response as Usuario[]));
  }
  public getUsuariosAVencerPaginado(page:any,size?:any): Observable<any> {
    return this.http
      .get(`${environment.localBack}usuario/proxima-vencer`,
      { 
        params: {
          page:page,
          size:size
        }
        })
      .pipe(map((response) => response as Usuario[]));
  }
  public crear(usuarioParametro: any,recaptchaToken:any): any {
    let headers = new HttpHeaders({'recaptcha-token':recaptchaToken})
    return this.http.post(
      `${environment.localBack}usuario`,
      usuarioParametro,{headers: headers}
    );
  }
  public editar(usuarioParametro: any,recaptchaToken:any): any {
    let headers = new HttpHeaders({'recaptcha-token':recaptchaToken})
    return this.http.put(
      `${environment.localBack}usuario`,
      usuarioParametro,{headers: headers}
    );
  }
  public eliminar(usuarioParametro: any,recaptchaToken:any): any {
    let headers = new HttpHeaders({'recaptcha-token':recaptchaToken})
    return this.http.delete(
      `${environment.localBack}usuario/`+ usuarioParametro,{headers: headers}
    );
  }
  public getUsuariosPorDocumento(): Observable<Usuario[]> {
    const documento: string = '8033205'
    return this.http
      .get(`${environment.localBack}usuario/consultar-por-lider/` + documento,)
      .pipe(map((response) => response as Usuario[]));
  }
  // Usuario Lider
  public getUsuariosLider(): Observable<Usuario[]> {
    return this.http
      .get(`${environment.localBack}usuario/usuarios-a-cargo`)
      .pipe(map((response) => response as Usuario[]));
  }
    // Usuario Lider por tren
    public getUsuariosLiderTren(): Observable<Usuario[]> {
      return this.http
        .get(`${environment.localBack}usuario/usuarios-a-cargo-lider-tren`)
        .pipe(map((response) => response as Usuario[]));
    }
  public getLideres(): Observable<Usuario[]> {
    return this.http
      .get(`${environment.localBack}usuario/consultar-lideres`)
      .pipe(map((response) => response as Usuario[]));
  }
  // Usuario Vacante
  public crearVacante(usuarioParametro: any,recaptchaToken:any): any {
    let headers = new HttpHeaders({'recaptcha-token':recaptchaToken})
    return this.http.post(
      `${environment.localBack}usuario/capacidades`,
      usuarioParametro,{headers: headers}
    );
  }
  public editarVacante(usuarioParametro: any,recaptchaToken:any): any {
    let headers = new HttpHeaders({'recaptcha-token':recaptchaToken})
    return this.http.put(
      `${environment.localBack}usuario/capacidades`,
      usuarioParametro,{headers: headers}
    );
  }
  public getVacantes(): Observable<Usuario[]> {
    return this.http
      .get(`${environment.localBack}usuario/capacidades`)
      .pipe(map((response) => response as Usuario[]));
  }

  public getNotificaciones(): Observable<Usuario[]> {
    return this.http
      .get(`${environment.localBack}usuario/notificar`)
      .pipe(map((response) => response as Usuario[]));
  }
  
  public getUsuariosIncompletos(): Observable<Usuario[]> {
    return this.http
      .get(`${environment.localBack}usuario/informacion-incompleta`)
      .pipe(map((response) => response as Usuario[]));
  }
  public getUsuariosTrenIncompletos(): Observable<Usuario[]> {
    return this.http
      .get(`${environment.localBack}usuario/informacion-incompleta-usuarios-lider-tren`)
      .pipe(map((response) => response as Usuario[]));
  }
  public consultarLiderLogueado(): Observable<Usuario> {
    return this.http
      .get(`${environment.localBack}usuario/consultar-lider-logueado`)
      .pipe(map((response) => response as Usuario));
  }

  public getUsuariosFiltro(page:any,size?:any,filtro?:any): Observable<any> {
    return this.http
      .get(`${environment.localBack}usuario/filtro-general-paginado`,
      { 
        params: {
          page:page,
          size:size,
          filtro:filtro
        }
        })
      .pipe(map((response) => response as Usuario[]));
  }

  public getFiltros(page:any,size?:any,filtro?:any): Observable<any> {    
    let filtroFiltro: any = {
      page:page,
      size:size               
    }
    if (filtro.roles != undefined)filtroFiltro.roles=filtro.roles
    if (filtro.dominios != undefined)filtroFiltro.dominios=filtro.dominios
    if (filtro.trenes != undefined)filtroFiltro.trenes=filtro.trenes
    if (filtro.empleadores != undefined)filtroFiltro.empleadores=filtro.empleadores
    if (filtro.proveedores != undefined)filtroFiltro.proveedores=filtro.proveedores
    if (filtro.vagones != undefined)filtroFiltro.vagones=filtro.vagones
    if(filtro.subdominios!= undefined)filtroFiltro.subdominios=filtro.subdominios
    
    return this.http
      .get(`${environment.localBack}usuario/listar-por-filtros`,
      {     
        params: filtroFiltro
        })
      .pipe(map((response) => response as Usuario[]));
  }
  public getUsuariosCargoFiltros(filtro?:any): Observable<any> {    
    let filtroFiltro: any = {
    }
    if (filtro.roles != undefined)filtroFiltro.roles=filtro.roles
    if (filtro.dominios != undefined)filtroFiltro.dominios=filtro.dominios
    if (filtro.trenes != undefined)filtroFiltro.trenes=filtro.trenes
    if (filtro.empleadores != undefined)filtroFiltro.empleadores=filtro.empleadores
    if (filtro.proveedores != undefined)filtroFiltro.proveedores=filtro.proveedores
    if (filtro.vagones != undefined)filtroFiltro.vagones=filtro.vagones
    if(filtro.subdominios!= undefined)filtroFiltro.subdominios=filtro.subdominios
    
    return this.http
      .get(`${environment.localBack}usuario/usuarios-a-cargo-con-filtro`,
      {     
        params: filtroFiltro
        })
      .pipe(map((response) => response as Usuario[]));
  }
  public getUsuariosCargoTrenFiltros(filtro?:any): Observable<any> {  
    let filtroFiltro: any = {
              
    }
    if (filtro.roles != undefined)filtroFiltro.roles=filtro.roles
    if (filtro.dominios != undefined)filtroFiltro.dominios=filtro.dominios
    if (filtro.trenes != undefined)filtroFiltro.trenes=filtro.trenes
    if (filtro.empleadores != undefined)filtroFiltro.empleadores=filtro.empleadores
    if (filtro.proveedores != undefined)filtroFiltro.proveedores=filtro.proveedores
    if (filtro.vagones != undefined)filtroFiltro.vagones=filtro.vagones
    if(filtro.subdominios!= undefined)filtroFiltro.subdominios=filtro.subdominios
    
    return this.http
      .get(`${environment.localBack}usuario/usuarios-a-cargo-lider-tren-con-filtro`,
      {     
        params: filtroFiltro
        })
      .pipe(map((response) => response as Usuario[]));
  }
  
}
